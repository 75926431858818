const backend_codes = require("../../backend_codes");
const Handler = require("./Handler");


class LoginHandler extends Handler {
    _doHandle(action) {
        return action === backend_codes.LOGIN
    }

    _execute(msg) {
        this._setDashboardName(msg.dashboard_name)
        this._reloadResources(msg.content)
        this._data.showModal = false
    }
}

module.exports = LoginHandler