/**
 * Handler abstrato para os eventos de mensagem do backend
 * 
 * Implemente o método _doHandle(action: Number) que deve retornar um true|false e o método _execute(msg) que deve operar sobre this._data
 * usado no callbackCommand
 */
class Handler {
    constructor(data) {
        this._data = data
    }

    handle(msg) {
        if (!this._doHandle(msg.action))
            return false

        this._execute(msg)
        return true
    }

    _setDashboardName(name) {
        this._data.dashboardName = name
    }

    _reloadResources(resources) {
        if (resources)
            resources.sort((a, b) => (a.priority || 0) - (b.priority || 0))
        this._data.resources.splice(0)
        if (resources) {
            for(const resource of resources) {
                if (resource.os)
                    resource.os.note_start_timestamp = new Date(resource.os.note_start_timestamp)

                if (resource.last_note)
                    resource.last_note.timestamp = new Date(resource.last_note.timestamp)

                this._data.resources.push(resource)
            }
            this._data.showModal = false
        }
    }

    _getResource(id) {
        return this._data.resources.find(r => r.id === id)
    }

    _getResourceIndex(id) {
        return this._data.resources.findIndex(r => r.id === id)
    }
}

module.exports = Handler